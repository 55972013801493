<template>
  <v-container grid-list-xl>
    <v-layout wrap justify-space-between>
      <v-flex xs12 md12>
        <v-alert :value="alert" type="success">Başarıyla kaldedildi.</v-alert>
      </v-flex>
      <v-flex xs12 md12>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu"
              v-model="menu"
              :close-on-content-click="false"
              :return-value.sync="startDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startDate"
                  label="Start Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu.save(startDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
          <v-spacer></v-spacer>
          <v-col cols="12" sm="6" md="4">
            <v-menu
              ref="menu2"
              v-model="menu2"
              :close-on-content-click="false"
              :return-value.sync="endDate"
              transition="scale-transition"
              offset-y
              min-width="auto"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="endDate"
                  label="End Date"
                  prepend-icon="mdi-calendar"
                  readonly
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="endDate" no-title scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="menu2 = false">
                  Cancel
                </v-btn>
                <v-btn text color="primary" @click="$refs.menu2.save(endDate)">
                  OK
                </v-btn>
              </v-date-picker>
            </v-menu>
          </v-col>
        </v-row>
        <v-switch
          v-model="model.isActive"
          :label="`Is Active`"
          hide-details
        ></v-switch>
        <v-text-field
          v-model="model.backgroundPicture"
          label="Background Picture"
          required
        ></v-text-field>
        <v-text-field
          v-model="model.rewards[0].targetXp"
          label="Target XP"
          hide-details
        ></v-text-field>
        <v-text-field
          v-model="model.rewards[0].free.description"
          label="Rewards Free Description"
          hide-details
        ></v-text-field>
        <v-autocomplete
          v-model="model.rewards[0].free.rewardType"
          label="Rewards Free Reward Type"
          :counter="max"
          :items="rewardType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
        <v-text-field
          v-model="model.rewards[0].free.value"
          label="Rewards Free Value"
          hide-details
        ></v-text-field>
        <v-text-field
          v-model="model.rewards[0].premium.description"
          label="Rewards Premium Description"
          hide-details
        ></v-text-field>
        <v-autocomplete
          v-model="model.rewards[0].premium.rewardType"
          label="Rewards Premium Reward Type"
          :counter="max"
          :items="rewardType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
        <v-text-field
          v-model="model.rewards[0].premium.value"
          label="Rewards Premium Value"
          hide-details
        ></v-text-field>
        <v-text-field
          v-model="model.bigReward.description"
          label="Big Reward Description"
          hide-details
        ></v-text-field>
        <v-autocomplete
          v-model="model.bigReward.rewardType"
          label="Big Reward Reward Type"
          :counter="max"
          :items="rewardType"
          item-value="key"
          item-text="value"
          placeholder="Select..."
        ></v-autocomplete>
        <v-text-field
          v-model="model.bigReward.value"
          label="Big Reward Value"
          hide-details
        ></v-text-field>
      </v-flex>
      <v-btn color="success" @click="save">Save</v-btn>
    </v-layout>
  </v-container>
</template>
<script>
import rewardType from "@/lib/rewardType";
import router from "@/router";
export default {
  data: () => ({
    model: {
      startTime: new Date(Date.now()),
      endTime: new Date(Date.now()),
      isActive: false,
      backgroundPicture: "",
      bigReward: {
        description: "",
        rewardType: 0,
        value: 0,
      },
      rewards: [
        {
          targetXp: 0,
          free: {
            description: "",
            rewardType: 0,
            value: 0,
          },
          premium: {
            description: "",
            rewardType: 0,
            value: 0,
          },
        },
      ],
    },
    rewardType: rewardType,
    alert: false,
    max: 120,
    startDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    endDate: new Date(Date.now() - new Date().getTimezoneOffset() * 60000)
      .toISOString()
      .substr(0, 10),
    menu: false,
    modal: false,
    menu2: false,
  }),

  methods: {
    async save() {
      (this.model.startTime =
        new Date(
          new Date(this.startDate).getTime() +
            new Date().getTimezoneOffset() * 60000
        ) / 1000),
        (this.model.endTime =
          new Date(
            new Date(this.endDate).getTime() +
              new Date().getTimezoneOffset() * 60000
          ) / 1000),
        await this.$store.dispatch("seasonPass/post", this.model);
      router.push("/seasonPass");
    },
  },
  validateField() {
    this.$refs.form.validate();
  },
  computed: {},
};
</script>
